
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';
    import {namespace} from 'vuex-class';
    import {Prop} from 'vue-property-decorator';
    import {formHelper} from '@/helpers';
    import PhoneInput from '@/components/forms/PhoneInput.vue';
    

    import {
        IClient,
        IUpdateClientDataByPro,
        IAccount,
    } from '@/types';
    import {
        ClientModule,
        AccountModule,
    } from '@/store/modules';

    const clientNamespace = namespace('client');

    @Component<BookingClient>({
      components: {
        PhoneInput
      },
    })
    export default class BookingClient extends Vue {
      public dialog: boolean = true;
      public selectedClient: IClient | null = null;
      public isFetching: boolean = true;
      public isSelected: boolean = true;
      public emailRules = formHelper.getEmailsRules();
      public email: string = '';
      public cellPhone: string = '';

      @Prop({
          type: Object,
          required: true,
      })
      public account!: IAccount;

      @clientNamespace.Getter('clientList')
      public clientList!: IClient[];

      public rewriteName(client: IClient) {
        if (client.cell_phone) {
          return client.full_name + ' - ' + client.cell_phone + ' - ' + client.organization_name;
        } else {
          return client.full_name + ' - ' + client.organization_name;
        }
      }

      public fetchDatas() {
        this.isFetching = true;


        getModule(ClientModule, this.$store)
          .fetchClients(this.$props.account.id)
            .then( () => {
              this.isFetching = false;
            },
          )
        ;
      }

      public handleSelect() {
        this.isSelected = false;
      }

      public submitSelectClient() {
        
        const client = this.clientList.find((el: IClient) => el.id === this.selectedClient?.id);
        const params: IUpdateClientDataByPro = {
          cell_phone: '',
          email: '',
          organization_id: '',
        };

        if (client) {
          this.isSelected = true;
          params.client = client;

          if (this.cellPhone !== '') {
            params.cell_phone = this.cellPhone;
            client.cell_phone = this.cellPhone;
          } else {
            params.cell_phone = client.cell_phone;
          }

          if (this.email !== '') {
            params.email = this.email;
            client.email = this.email;
          } else {
            params.email = client.email as string;
          }
          
          if (this.cellPhone || this.email) {
            getModule(AccountModule, this.$store).updateClientByPro(params)
              .then(() => {
                getModule(AccountModule, this.$store).clientSelectPro(client);
                this.$emit('emitClientSelected', client);
                this.dialog = false;
              },
            );
          } else {
            getModule(AccountModule, this.$store).clientSelectPro(client);
            this.$emit('emitClientSelected', client);
            this.dialog = false;
          }

        }
      }

      public verifPhoneRule(phone: string|null) {
            if (!phone) {
                this.cellPhone = '';
            } else {
                this.cellPhone = phone;
            }
        }

      public mounted() {
        this.fetchDatas();
      }

    }
