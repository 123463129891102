
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Route, NavigationGuardNext} from 'vue-router';
    import {getModule} from 'vuex-module-decorators';
    import {store} from '@/store';
    import {namespace} from 'vuex-class';
    import {localStorageService} from '@/storage/localstorage.service';

    import {
        AccountModule,
        BookingModule,
        SnackbarModule,
        TuvcomModule,
        OrganizationModule,
    } from '@/store/modules';

    import {
        tuvcomHelper, 
        bannerHelper
    } from '@/helpers';

    import {
      IBooking,
      IUser,
      ICreateBookingFilesResult,
      ICreateBookingFilesError,
      IOrganization,
      IOrganizationRule,
      IOrganizationInstruction,
      IBookingProcessing
    } from '@/types';

    const bookingNamespace = namespace('booking');
    const accountNamespace = namespace('account');
    const organizationNamespace = namespace('organization');

    @Component<BookingSummary>({
        components: {
        },
        beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
            const accountModule = getModule(AccountModule, store);
            
            if (accountModule.isLoggedIn) {
                next();
            } else {
                next({name: 'step-logger', query: to.query});
            }
            next();
        },
    })
    export default class BookingSummary extends Vue {
        public filesToUpload: File[] = [];
        public isUploadingFiles: boolean = false;
        public openDialog: boolean = false;
        public mode: string = 'funnel';
        public organizationLoaded: boolean = false;
        public booking_quotation: string = localStorageService.load('booking_quotation') ?? '';
        public dynaban: string = '';

        @bookingNamespace.Getter('latestCreatedBooking')
        public createdBooking!: IBooking;

        @accountNamespace.Getter('loggedUser')
        public loggedUser!: IUser;

        @organizationNamespace.Getter('organizationGet')
        public organization!: IOrganization;

        @bookingNamespace.Getter('bookingProcess')
        public bookingProcess!: IBookingProcessing;
        

        public mounted() {
            if (!this.createdBooking) {
                this.mode = 'simple';
                return;
            }
            this.fetchOrganization();
        }
        public created() {
            this.dynaban = bannerHelper.getDynaBan();
        }


       public fetchOrganization() {
        this.organizationLoaded = false;
        const spltSlug: string[] | null | undefined = this.createdBooking.agenda.slug?.split('/');
        if (spltSlug) {
            getModule(OrganizationModule, this.$store)
                .getBySlug(`${spltSlug[0]}/${spltSlug[1]}/${spltSlug[2]}`)
                    .then( () => {
                        this.organizationLoaded = true;
                    }
                )
            }
        }

        get postBookingInstructionsList() {
            if (!this.createdBooking?.data) {
                return [];
            }

            return this.createdBooking.data
                .filter((elm) => !!(elm as IOrganizationRule|IOrganizationInstruction).post_booking_instruction)
                .map((elm: IOrganizationRule|IOrganizationInstruction) => {
                    const formattedInstruction = elm.used_variables.reduce((acc: string, variable: any) => {
                        Object.keys(variable).forEach((varKey) => {
                            acc = acc.replace(`:?${varKey}:?`, variable[varKey]);
                        });

                        return acc;
                    }, `${elm.post_booking_instruction}`);
                    
                    return {
                        id: elm.id,
                        post_booking_instruction: formattedInstruction,
                        organization_instruction_files: elm.organization_instruction_files,
                    };
                })
            ;
        }

        get bottomButtonContainerMobile() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return [
                    'fixed-btn',
                    'd-flex',
                    'justify-center',
                    'align-center',
                ];
            }

            return [];
        }

        private uploadFiles() {
            this.isUploadingFiles = true;

            const formData = new FormData();

            if (!this.createdBooking) {
                return;
            }

            this.filesToUpload.forEach((file: File, index: number) => {
                formData.append(`files[${index}]`, file);
            });

            (
                tuvcomHelper.isTuvcom(this.$route) ?
                getModule(TuvcomModule, this.$store) :
                getModule(BookingModule, this.$store)
            )
                .addFiles(
                    tuvcomHelper.isTuvcom(this.$route) ?
                    formData :
                    {
                        client_id: this.loggedUser?.client?.organization_id,
                        type: 'veterinarian',
                        booking_id: this.createdBooking.id,
                        files: formData,
                    } as any,
                )
                .then((response: ICreateBookingFilesResult) => this.onUploadSuccess(response))
                .catch(() => this.onUploadError())
                .finally(() => this.isUploadingFiles = false);
        }

        private onUploadError() {
            getModule(SnackbarModule, this.$store)
                .display({
                    message: `Une erreur est survenue pendant l'envoi`,
                    position: 'top',
                    color: 'error',
                })
            ;
        }

        private onUploadSuccess(response: ICreateBookingFilesResult) {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            if (response.errors.length > 0) {
                const failedFiles =
                    response.errors.map((value: ICreateBookingFilesError) => {
                        return value.file;
                    })
                        .join(', ')
                ;

                snackbarModule.display({
                    message: `Une erreur est survenue pendant l'envoi des fichiers suivant : ${failedFiles}`,
                    position: 'top',
                    color: 'error',
                });
            } else {
                snackbarModule.display({
                    message: 'Vos fichiers ont bien été ajoutés !',
                    position: 'top',
                    color: 'success',
                });
            }

            this.filesToUpload = [];
        }
        
    }
