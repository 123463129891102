
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {Route, NavigationGuardNext} from 'vue-router';
    import {getModule} from 'vuex-module-decorators';
    import {Watch} from 'vue-property-decorator';

    import {formHelper} from '@/helpers';
    import {store} from '@/store';

    import {
      AccountModule,
    } from '@/store/modules';

    import LoginFormCard from '@/components/LoginFormCard.vue';
    import RegisterFormCard from '@/components/RegisterFormCard.vue';

    const accountNamespace = namespace('account');

    @Component<BookingLogger>({
        components: {
            LoginFormCard,
            RegisterFormCard,
        },
        beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
            const accountModule = getModule(AccountModule, store);

            if (accountModule.isValidated) {
                if (to.query.is_temporary_reason === '1') {
                    next({name: 'step-details', query: to.query});
                } else {
                    next({name: 'step-animal', query: to.query});
                }
            }

            next();
        },
    })
    export default class BookingLogger extends Vue {
        public identificationMode: string|null = null;
        public emailValid: boolean = false;
        public email: string|null = null;
        public emailRules = formHelper.getEmailsRules();

        @accountNamespace.Getter('isLoggedIn')
        public isLoggedIn!: boolean;

        @accountNamespace.Getter('isValidated')
        public isValidated!: boolean;

        public goToRegister() {
            this.email = null;
            this.identificationMode = 'register';
        }

        public goToLogin() {
            this.email = null;
            this.identificationMode = 'login';
        }

        public checkEmailExists() {
            if (!this.email) {
                return;
            }

            this.$api.account
                .exists(this.email as string)
                .then((exists: boolean) => {
                    if (exists) {
                        this.identificationMode = 'login';
                    } else {
                        this.identificationMode = 'register';
                    }
                })
            ;
        }

        @Watch('isValidated')
        private redirectAfterValidated() {
            if (this.isValidated) {
                this.$router.push({name: 'step-animal', query: this.$route.query});
            }
        }

        @Watch('isLoggedIn')
        private redirectAfterLogged() {
            if (this.isLoggedIn && this.isValidated) {
                this.$router.push({name: 'step-animal', query: this.$route.query});
            }
        }

    }
