
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Route, NavigationGuardNext} from 'vue-router';
    import {getModule} from 'vuex-module-decorators';
    import {store} from '@/store';
    import {namespace} from 'vuex-class';
    import {DateTime} from 'luxon';

    import {
      IAgenda,
      IAnimal,
      IBookingProcessing,
      IClient,
      ISpecies,
      IVeterinarian,
      IUser,
      IAnimalToCreate,
    } from '@/types';

    import {
        AccountModule,
        AnimalModule,
        BookingModule,
    } from '@/store/modules';

    import AnimalFormCard from '@/components/AnimalFormCard.vue';
    import AnimalInsurance from '@/components/AnimalInsurance.vue';
    import BookingClient from '@/components/bookingFunnel/BookingClient.vue';

    const animalNamespace = namespace('animal');
    const accountNamespace = namespace('account');
    const bookingNamespace = namespace('booking');

    @Component<BookingAnimal>({
        components: {
            AnimalFormCard,
            AnimalInsurance,
            BookingClient,
        },
        beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
            const accountModule = getModule(AccountModule, store);

            if (to.query.is_temporary_reason === '1') {
                next({name: 'step-details', query: to.query});
            } else if (accountModule.isValidated) {
                next();
            } else {
                next({name: 'step-logger', query: to.query});
            }
        },
        beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
            this.fetchDataFromQuery();
            next();
        },
    })
    export default class BookingAnimal extends Vue {
        public selectedAnimal: IAnimal | null = null;
        public animalsLoaded: boolean = false;
        public createAnimalDialog: boolean = false;
        public animalToCreate?: IAnimalToCreate | null;
        public species: ISpecies|null = null;
        public loading: boolean = false;
        public agenda?: IAgenda;
        public selectedClientByPro: IClient|null = null;
        public isPro: boolean = false;

        @animalNamespace.Getter('animalsList')
        public animals!: IAnimal[];

        @accountNamespace.Getter('loggedClient')
        public client!: IClient;

        @bookingNamespace.Getter('bookingProcess')
        public bookingProcess!: IBookingProcessing;

        @accountNamespace.Getter('loggedUser')
        public loggedUser!: IUser;

        get matchAnimals(): IAnimal[] {
            if (!this.$route.query.species_id) {
                return [];
            }

            return this.animals.filter((animal: IAnimal) => {
                return animal.species.id === (this.species as ISpecies).id;
            });
        }

        get bottomButtonContainerMobile() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return [
                    'fixed-btn',
                    'd-flex',
                    'justify-center',
                    'align-center',
                ];
            }

            return [];
        }

        get showInsurance() {
            if (!this.selectedAnimal?.insurance) {
                return true;
            }

            const updatedAt = DateTime.fromISO(this.selectedAnimal?.insurance?.updated_at as string);
            const now = DateTime.local();
            const diff = now.diff(updatedAt, ['years']);

            return diff.years >= 1;
        }

        public emitClientSelected(targetClient: IClient, route: Route) {
            this.isPro = false;
            this.loading = false;
            this.selectedClientByPro = targetClient;

            getModule(BookingModule, this.$store)
                .handlerBookingProcess({client: targetClient})
                    .then(() => {
                        this.fetchDataFromQuery();
                    },
                )
            ;
        }

        public nextStep(route: Route) {
            getModule(BookingModule, this.$store)
                .handlerBookingProcess({animal: this.selectedAnimal})
                    .then(() => {
                        return this.$router.push(
                            {
                                name: 'step-information',
                                query: Object.assign(this.$route.query, { selected_animal: this.selectedAnimal?.id }),
                            },
                        );
                    },
                )
            ;
        }

        public toggleAnimal(animal: IAnimal) {
            this.selectedAnimal = this.selectedAnimal === animal ? null : animal;
        }

        public loadAnimals() {
            if (this.selectedClientByPro !== null) {
                getModule(AnimalModule, this.$store)
                    .fetchAnimalsPro(
                        {
                            clientId: this.selectedClientByPro.id,
                            organizationId: this.selectedClientByPro.organization_id,
                        },
                    )
                    .then(() => {
                            this.animalsLoaded = true;
                        },
                    )
                ;
            } else {
                getModule(AnimalModule, this.$store)
                    .fetchAnimals(this.client.id)
                        .then(() => {
                            this.animalsLoaded = true;
                            if (this.matchAnimals.length === 0) {
                                this.openCreateAnimalDialog();
                            }
                        },
                    )
                ;
            }
        }

        public openCreateAnimalDialog() {
            this.animalToCreate = {
                species: this.species,
            };

            this.createAnimalDialog = true;
        }

        public closeCreateAnimalDialog() {
            this.createAnimalDialog = false;

            if (this.matchAnimals.length === 1) {
                this.toggleAnimal(this.matchAnimals[0]);
            }
        }

        public fetchDataFromQuery() {
            const animalModule = getModule(AnimalModule, this.$store);
            const promises: Array<Promise<any>> = [];

            this.loading = true;
            this.animalsLoaded = false;

            promises.push(
                animalModule
                    .fetchSpeciesById(this.$route.query.species_id as string)
                    .then((species) => this.species = species),
            );

            this.loadAnimals();

            const agenda = this.bookingProcess.agenda;

            if (agenda) {
                promises.push(
                    this.$api.agenda
                        .getById(agenda.id as string)
                        .then((veterinarian: IVeterinarian) => {
                            this.agenda = veterinarian.agendas?.find((val: IAgenda) => val.id === agenda.id);
                        }),
                );
            }

            Promise.all(promises)
                .then(() => {
                    this.loading = false;
                })
            ;
        }

        public created() {
            if (this.loggedUser?.account?.type !== 'veterinarian') {
                this.fetchDataFromQuery();
            }

            getModule(AnimalModule, this.$store).fetchSpeciesById(this.$route.query.species_id as string)
                    .then((species) => this.species = species);
        }

        public mounted() {
            if (this.loggedUser?.account?.type === 'veterinarian') {
                this.isPro = true;
            }

            getModule(AnimalModule, this.$store).fetchSpeciesById(this.$route.query.species_id as string)
                    .then((species) => this.species = species);
        }

    }
