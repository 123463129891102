
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';

    import {
        IAnimal,
        IClient,
        IOrganization,
        IReason,
        IVeterinarian,
    } from '@/types';

    import {
        addressHelper,
    } from '@/helpers';

    @Component<BookingSummary>({})
    export default class BookingSummary extends Vue {
        @Prop({
            type: Object,
            required: false,
        })
        public veterinarian!: IVeterinarian;

        @Prop({
            type: Object,
            required: true,
        })
        public organization!: IOrganization;

        @Prop({
            type: String,
            required: true,
        })
        public appointmentDate!: string;

        @Prop({
            type: Object,
            required: false,
        })
        public reason!: IReason;

        @Prop({
            type: Object,
            required: false,
        })
        public client!: IClient;

        @Prop({
            type: Object,
            required: false,
        })
        public selectedAnimal!: IAnimal|null;

        get organizationAddress() {
            if (!this.organization) {
                return null;
            }

            return addressHelper.getHtmlAddress(this.organization);
        }
    }
