
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {DateTime} from 'luxon';
    import {Route, NavigationGuardNext} from 'vue-router';
    import {getModule} from 'vuex-module-decorators';

    import {
        IOrganization,
        IVeterinarian,
        IReason,
        IAgenda,
        ISpecies,
    } from '@/types';

    import {AnimalModule} from '@/store/modules';
    import BookingWidget from '@/components/BookingWidget.vue';

    @Component<BookingDetails>({
        components: {
            BookingWidget,
        },
        beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
            if (to.query.is_temporary_reason === '1') {
              next();
            } else {
              next({name: 'step-animal', query: to.query});
            }
        },
        beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
            this.fetchDataFromQuery(to);
            next();
        },
    })
    export default class BookingDetails extends Vue {
        public time: DateTime|null = null;
        public reason: IReason|null = null;
        public veterinarian: IVeterinarian|null = null;
        public species: ISpecies|null = null;
        public loaded: boolean = false;

        get agenda(): IAgenda | null {
            if (!(this.veterinarian && this.veterinarian.agendas)) {
                return null;
            }

            const agenda =  this.veterinarian.agendas.find((val: IAgenda) => {
                return val.id === this.$route.query.agenda_id;
            });

            return agenda ? agenda : null;
        }

        get organization(): IOrganization | null {
            return this.agenda ? this.agenda.organization as IOrganization : null;
        }

        get appointmentDate() {
            if (!this.time) {
                return null;
            }

            return this.time
                .setZone((this.organization as IOrganization).timezone)
                .toLocaleString(DateTime.DATETIME_MED)
            ;
        }

        public fetchDataFromQuery(route: Route) {
            this.loaded = false;

            if (route.query.time) {
                this.time = DateTime.fromISO(route.query.time as string);
            }

            const promises: Array<Promise<any>> = [];

            if (route.query.reason_id) {
                promises.push(
                    this.$api.booking
                        .getReasonById(route.query.reason_id as string)
                        .then((reason) => this.reason = reason),
                );
            }

            if (route.query.species_id) {
                const animalModule = getModule(AnimalModule, this.$store);

                promises.push(
                    animalModule
                        .fetchSpeciesById(route.query.species_id as string)
                        .then((species) => this.species = species),
                );
            }

            if (route.query.agenda_id) {
                promises.push(
                    this.$api.agenda
                        .getById(route.query.agenda_id as string)
                        .then((veterinarian: IVeterinarian) => {
                            this.veterinarian = veterinarian;
                        }),
                );
            }

            Promise
                .all(promises)
                .then(() => {
                    this.loaded = true;
                })
            ;
        }

        public created() {
            this.fetchDataFromQuery(this.$route);
        }
    }
