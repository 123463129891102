
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Route, NavigationGuardNext} from 'vue-router';
    import {getModule} from 'vuex-module-decorators';
    import {store} from '@/store';
    import {namespace} from 'vuex-class';
    import {DateTime} from 'luxon';

    import BlacklistedDialog from '@/components/dialogs/BlacklistedDialog.vue';

    import {
      BookingModule,
    } from '@/store/modules';

    import {
        IBookingProcessing,
        IClient,
        IUser,
    } from '@/types';

    import {
        AccountModule,
    } from '@/store/modules';

    const accountNamespace = namespace('account');
    const bookingNamespace = namespace('booking');

    @Component<BookingInformation>({
        components: {
            BlacklistedDialog,
        },
        beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
            const accountModule = getModule(AccountModule, store);
            const bookingProcessing = store.getters['booking/bookingProcess'];

            if (accountModule.isLoggedIn) {
                if (!bookingProcessing.animal) {
                    next({name: 'step-animal', query: to.query});
                } else {
                    next();
                }
            } else {
                if (!bookingProcessing.animal) {
                    next({name: 'step-animal', query: to.query});
                } else {
                    next({name: 'step-logger', query: to.query});
                }
            }


        },
    })
    export default class BookingInformation extends Vue {
        public commentsValid: boolean = false;
        public comments: string|null = null;
        public submitting: boolean = false;
        public commentsConfirmed: boolean = false;
        public time: DateTime|null = null;
        public error: string|null = null;
        public bookingError: boolean = false;
        public isBlacklisted: string|null = null;
        public dialogBlacklist: boolean = false;

        public commentsRules = [
            (v: string) => !!v || 'Veuillez préciser la raison de votre visite (min. 20 caractères)',
            (v: string) => (v && v.length >= 20) || 'Minimum 20 caractères',
            (v: string) => !/\s{2,}/.test(v) || "Les espaces blancs consécutifs ne sont pas acceptés"
        ];

        @accountNamespace.Getter('loggedClient')
        public client!: IClient;

        @bookingNamespace.Getter('bookingProcess')
        public bookingProcess!: IBookingProcessing;

        @accountNamespace.Getter('loggedUser')
        public loggedUser!: IUser;

        get bottomButtonContainerMobile() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return [
                    'fixed-btn',
                    'd-flex',
                    'justify-center',
                    'align-center',
                ];
            }

            return [];
        }

        public nextStep(route: Route) {
            getModule(BookingModule, this.$store)
                .handlerBookingProcess({public_comment: this.comments})
                    .then(() => {
                        return this.$router.push(
                            {
                                name: 'step-insurance',
                                query: Object.assign(this.$route.query),
                            },
                        );
                    },
                )
            ;
        }

    }
